/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { hasMany, attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isNone, isEmpty, isPresent } from '@ember/utils';
import { put, post, get } from 'embercom/lib/ajax';
import {
  states,
  seriesExitBehaviors,
  entryBehaviors,
  seriesDuplicationStatuses,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import RSVP from 'rsvp';
import { fragment } from 'ember-data-model-fragments/attributes';
import { dependentKeyCompat } from '@ember/object/compat';
import TaggingMixin from 'embercom/models/mixins/tagging-mixin';
import { task } from 'ember-concurrency-decorators';
import { contentWrapperTypes } from 'embercom/models/data/outbound/constants';
import { UPDATE_TAGGING_URL } from 'embercom/lib/knowledge-hub/constants';

export default class Series extends Model.extend(TaggingMixin) {
  entityType = objectTypes.series;
  @service seriesEditorService;
  @service store;
  @service appService;
  @service outboundHomeService;
  @service notificationsService;
  @service intl;
  @hasMany('series/node', { async: false }) nodes;
  @hasMany('series/edges', { async: false }) edges;
  @hasMany('series/annotations', { async: false }) annotations;
  @hasMany('outbound/content-statistic', { async: false }) stats;
  @hasMany('tag', { async: true }) tags;
  @hasMany('tagging') taggings;
  @belongsTo('series/changelog', { inverse: null, async: false }) latestChangelog;
  @belongsTo('admin', { async: false }) createdBy;
  @attr('number') state;
  @attr('string') title;
  @fragment('stats-system/goal') goal;
  @attr('number') entryBehavior;
  @attr('number') exitBehavior;
  @fragment('predicates/predicate-group') exitPredicateGroup;
  @attr('date') wentLiveAt;
  @attr('date') createdAt;
  @attr('number') duplicationStatus;
  @fragment('matching-system/company-prioritizer') companyPrioritizer;
  @attr({ defaultValue: () => [] }) deletedNodeIds;
  @attr({ defaultValue: () => [] }) deletedEdgeIds;
  @fragment('series/state-change') lastStateChange;

  _persistedNodeIds;
  _persistedEdgeIds;

  didLoad() {
    this._persistedNodeIds = this.nodes.map((node) => node.id);
    this._persistedEdgeIds = this.edges.map((edge) => edge.id);
    if (!this.isDraft) {
      this.refreshStats.perform();
    }
  }

  get isLive() {
    return this.state === states.live;
  }

  @dependentKeyCompat
  get isDraft() {
    return this.state === states.draft;
  }

  get isSingleEntry() {
    return !this.isMultipleEntry;
  }

  get isMultipleEntry() {
    return this.startingNodes.any((node) => {
      if (node.hasFetchedRuleset) {
        return node.ruleset.isMatchBehaviorMulti;
      } else {
        node.fetchRuleset();
        return false;
      }
    });
  }

  @dependentKeyCompat
  get hasGoal() {
    return isPresent(this.goal);
  }

  @dependentKeyCompat
  get hasExitRules() {
    return this.exitBehavior === seriesExitBehaviors.exitRules;
  }

  @dependentKeyCompat
  get duplicationInProgress() {
    return this.duplicationStatus === seriesDuplicationStatuses.inProgress;
  }

  @dependentKeyCompat
  get duplicationFailed() {
    return this.duplicationStatus === seriesDuplicationStatuses.failed;
  }

  get persistedNodeIds() {
    return this._persistedNodeIds;
  }

  get persistedEdgeIds() {
    return this._persistedEdgeIds;
  }

  get hasUnsavedChanges() {
    if (this.hasDirtyAttributes) {
      return true;
    }
    let currentNodeIds = this.nodes.map((node) => node.id);
    let currentEdgeIds = this.edges.map((edge) => edge.id);
    return (
      !_.isEqual(currentNodeIds, this.persistedNodeIds) ||
      !_.isEqual(currentEdgeIds, this.persistedEdgeIds) ||
      this.edges.any((edge) => edge.hasDirtyAttributes) ||
      this.nodes.any((node) => node.hasDirtyAttributes)
    );
  }

  get invalidStartingNodes() {
    return this.nodes.filter(
      (node) =>
        (node.isStarting && node.objectTypes.firstObject !== objectTypes.condition) ||
        (node.isStarting && node.isEnding),
    );
  }

  get hasInvalidStartingNodes() {
    if (isEmpty(this.nodes)) {
      return true;
    }

    if (this.isMultipleEntry && this.startingNodes.length > 1) {
      return true;
    }

    return isPresent(this.invalidStartingNodes);
  }

  get objectTypesUsed() {
    return this.nodes.map((node) => node.objectTypes.firstObject).uniq();
  }

  @dependentKeyCompat
  get contentTypesUsed() {
    return this.objectTypesUsed.filter((objectType) =>
      this.outboundHomeService.allObjectTypes.includes(objectType),
    );
  }

  async save() {
    await this.loadStartingRulesets;
    this.entryBehavior = this.isMultipleEntry
      ? entryBehaviors.multipleEntry
      : entryBehaviors.singleEntry;
    await this._clearDanglingEdges();
    await super.save(...arguments);
    this._clearLocallyCreatedEdges();
    this._persistedNodeIds = this.nodes.map((node) => node.id);
    this._persistedEdgeIds = this.edges.map((edge) => edge.id);
  }

  async activate() {
    let app = this.app;
    let params = {
      app_id: app.id,
      admin_id: app.currentAdmin.id,
    };
    await put(`/ember/series/series/${this.id}/activate`, params);
    return await this.reload();
  }

  async deactivate() {
    let app = this.app;
    let params = {
      app_id: app.id,
      admin_id: app.currentAdmin.id,
    };
    let response = await put(`/ember/series/series/${this.id}/deactivate`, params);
    this.store.pushPayload({ 'series/series': response });
    return this;
  }

  async duplicate() {
    let app = this.app;
    let params = {
      app_id: app.id,
      admin_id: app.currentAdmin.id,
    };
    let duplicateJSON = await post(`/ember/series/series/${this.id}/duplicate`, params);
    this.store.push(this.store.normalize('series/series', duplicateJSON));
    return this.store.peekRecord('series/series', duplicateJSON.id);
  }

  async exportData(params) {
    await post(`/ember/series/series/${this.id}/export`, params);
  }

  async archive() {
    let app = this.app;
    let params = {
      app_id: app.id,
      admin_id: app.currentAdmin.id,
    };
    await put(`/ember/series/series/${this.id}/archive`, params);
  }

  async updateSettings() {
    let app = this.app;
    let params = {
      app_id: app.id,
      admin_id: app.currentAdmin.id,
      exit_behavior: this.exitBehavior,
      exit_predicate_group: this.exitPredicateGroup?.serialize(),
      goal: this.goal?.serialize() || null,
      company_prioritizer: this.companyPrioritizer?.serialize(),
    };
    await put(`/ember/series/series/${this.id}/update_settings`, params);
  }

  async hasNewChanges() {
    let newNodeIds;
    let newEdgeIds;
    await get(`/ember/series/series/${this.id}`, { app_id: this.app.id }).then((response) => {
      newNodeIds = response.nodes.map((node) => node.id);
      newEdgeIds = response.edges.map((edge) => edge.id);
    });

    return (
      !_.isEqual(this.persistedNodeIds, newNodeIds) || !_.isEqual(this.persistedEdgeIds, newEdgeIds)
    );
  }

  async cancelSettingsChanges() {
    this.set('goal', this.seriesEditorService.settingsState.goal);
    this.set('exitBehavior', this.seriesEditorService.settingsState.exitBehavior);
    this.set('exitPredicateGroup', this.seriesEditorService.settingsState.exitPredicateGroup);
    this.set('companyPrioritizer', this.seriesEditorService.settingsState.companyPrioritizer);
  }

  _clearLocallyCreatedEdges() {
    this.locallyCreatedEdges.forEach((edge) => this.store.unloadRecord(edge));
  }

  async _clearDanglingEdges() {
    let currentNodeIds = this.nodes.map((node) => node.id);
    await Promise.all(
      this.edges.map((edge) => {
        if (
          !currentNodeIds.includes(edge.predecessor?.id) ||
          !currentNodeIds.includes(edge.successor?.id) ||
          (edge.isSplit && isEmpty(edge.edgeSplit))
        ) {
          if (isPresent(edge.id)) {
            let graphEdge = this.seriesEditorService.graph.edges.find(
              (graphEdge) => graphEdge.dataObject?.id === edge.id,
            );
            this.seriesEditorService.graph.edges.removeObject(graphEdge);
            this.deletedEdgeIds.push(edge.id);
          }
          this.store.unloadRecord(edge);
        }
      }),
    );
  }

  get app() {
    return this.appService.app;
  }

  get locallyCreatedEdges() {
    return this.edges.filter((edge) => isNone(edge.id));
  }

  edgeForNodes(predecessor, successor) {
    return this.edges.find(
      (edge) => edge.predecessor === predecessor && edge.successor === successor,
    );
  }

  get startingNodes() {
    return this.nodes.filter((node) => node.isStarting);
  }

  async audiencePreviewPredicates() {
    let rulesets = await this.loadStartingRulesets();
    return [
      {
        type: 'or',
        predicates: rulesets.map((ruleset) => ({
          type: 'and',
          predicates: ruleset.predicatesForAudiencePreview(),
        })),
      },
    ];
  }

  async loadStartingRulesets() {
    return await Promise.all(this.startingNodes.map((node) => node.fetchRuleset()));
  }

  async fetchAudiencePreview() {
    let startingNodeRulesets = await this.loadStartingRulesets();
    let audiencesForStartingNodes = Promise.all(
      startingNodeRulesets.map((ruleset) => ruleset.fetchAudiencePreview()),
    );

    let allStartingNodePredicates = await this.audiencePreviewPredicates();

    let totalAudienceParams = {
      app_id: this.app.id,
      predicates: allStartingNodePredicates,
      page: 1,
      per_page: 1,
      include_count: true,
    };

    let audience = await RSVP.hash({
      total: post('/ember/users/search.json', totalAudienceParams),
      perStartingNode: audiencesForStartingNodes,
    });

    return {
      total: audience.total.total_count,
      startingNodes: this.startingNodes.reduce((hash, node) => {
        hash[node.id] = node.ruleset.audiencePreview.count;
        return hash;
      }, {}),
    };
  }

  get taggable() {
    return {
      tags: this.tags,
      taggings: this.taggings,
      type: 'content',
      updateTaggings: (admin, addedTags, removedTags, initialTags) => {
        return this.updateTaggingsTask.perform(addedTags, removedTags);
      },
    };
  }

  @task
  *updateTaggingsTask(addedTags, removedTags) {
    yield put(UPDATE_TAGGING_URL, {
      app_id: this.app.id,
      added_tag_ids: addedTags.map((tag) => tag.id),
      removed_tag_ids: removedTags.map((tag) => tag.id),
      content_wrapper_id: this.id,
      content_wrapper_type: contentWrapperTypes.series,
    });
  }

  @task({ drop: true })
  *refreshStats() {
    try {
      let seriesWithStatistics = yield get(`/ember/series/series/${this.id}/get_stats`, {
        app_id: this.app.id,
        admin_id: this.appService.app.currentAdmin.id,
      });

      let stats = seriesWithStatistics.stats.map((stat) =>
        this.store.push(this.store.normalize('outbound/content-statistic', stat)),
      );
      this.stats = stats;

      seriesWithStatistics.nodes.forEach((nodeJson) => {
        let node = this.nodes.find((n) => n.id === nodeJson.id);
        if (node) {
          node.updateCounts(nodeJson);
        }
      });
    } catch (e) {
      console.error(e);
      console.error('Series stats refresh failed: ', e.message);
      this.notificationsService.notifyError(this.intl.t('outbound.series.stats-refresh-failure'));
    }
  }

  getNodeForId(nodeId) {
    return this.nodes.find((node) => node.id === nodeId);
  }
}
