/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { fragment, array } from 'ember-data-model-fragments/attributes';
import { validator, buildValidations } from 'ember-cp-validations';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { VERIFICATION_STATUS } from 'embercom/models/data/sms/constants';
import { inject as service } from '@ember/service';

const Validations = buildValidations({
  companyName: validator('presence', { presence: true }),
  companyWebsite: [validator('presence', { presence: true }), validator('format', { type: 'url' })],
  businessContactFirstName: validator('presence', { presence: true }),
  businessContactLastName: validator('presence', { presence: true }),
  businessContactPhone: validator('presence', { presence: true }),
  businessContactEmail: [
    validator('presence', { presence: true }),
    validator('format', { type: 'email' }),
  ],
  'businessContactAddress.addressLine1': validator('presence', { presence: true }),
  'businessContactAddress.city': validator('presence', { presence: true }),
  'businessContactAddress.country': validator('presence', { presence: true }),
  'businessContactAddress.state': validator('presence', { presence: true }),
  'businessContactAddress.zipCode': validator('presence', { presence: true }),
  estimatedMontlyVolume: validator('presence', { presence: true }),
  'mainUseCases.length': validator('number', { gt: 0 }),
  smsUseCasesDescription: [
    validator('presence', { presence: true }),
    validator('length', { max: 500 }),
  ],
  collectionMethod: validator('presence', { presence: true }),
  collectionOutsideOfIntercomDescription: [
    validator('presence', {
      presence: true,
      disabled: computed('model.collectionMethod', function () {
        return this.model.collectionMethod !== 'outside-of-intercom';
      }),
    }),
    validator('length', { max: 500 }),
  ],
  'collectionOutsideOfIntercomAttachements.length': validator('number', {
    gt: 0,
    disabled: computed('model.collectionMethod', function () {
      return this.model.collectionMethod !== 'outside-of-intercom';
    }),
  }),
  collectUsingIntercomConsent: validator('inclusion', {
    in: [true],
    disabled: computed('model.collectionMethod', function () {
      return this.model.collectionMethod !== 'using-intercom';
    }),
  }),
  privacyPolicyLink: validator('presence', { presence: true }),
  optInType: validator('presence', {
    presence: true,
    disabled: computed('model.{collectionMethod,app}', function () {
      return this.model.collectionMethod !== 'outside-of-intercom';
    }),
  }),
  productionMessageSample: [
    validator('presence', {
      presence: true,
    }),
    validator('length', { max: 450 }),
  ],
});
export default class TollFreeVerification extends Model.extend(Validations) {
  @service appService;

  @attr('number') phoneNumberId;
  @attr('boolean', { defaultValue: true }) draft;
  @attr('string') companyName;
  @attr('string') companyWebsite;
  @attr('string') businessContactFirstName;
  @attr('string') businessContactLastName;
  @attr('string') businessContactPhone;
  @attr('string') businessContactEmail;
  @fragment('sms/business-contact-address', { defaultValue: {} }) businessContactAddress;
  @attr('number') estimatedMontlyVolume;
  @array('number') mainUseCases;
  @attr('number') optInType;
  @attr('string') smsUseCasesDescription;
  @attr('string') productionMessageSample;
  @attr('string', { defaultValue: 'outside-of-intercom' }) collectionMethod;
  @attr('string') collectionOutsideOfIntercomDescription;
  @array('string') collectionOutsideOfIntercomAttachements;
  @attr('boolean', { defaultValue: false }) collectUsingIntercomConsent;
  @attr('string') privacyPolicyLink;
  @attr('string') termsAndConditionsLink;
  @attr('string', { defaultValue: VERIFICATION_STATUS.DRAFT }) status;
  @attr('string') submittedOn;

  toggleUseCase(mainUseCaseId) {
    if (this.mainUseCases.includes(mainUseCaseId)) {
      this.mainUseCases.removeObject(mainUseCaseId);
    } else {
      this.mainUseCases.pushObject(mainUseCaseId);
    }
  }

  get app() {
    return this.appService.app;
  }
}
